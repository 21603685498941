
.split .firstrow {
  border-bottom: 1px solid #ccc;
  padding:6px 0;
  .form-floating {
    padding: 0 6px;
    color: #000;
    font-weight: normal;
    margin-right: 8px !important;
    &.form-btns {
      padding:0;
      width: 10% !important;
    }
  }
}
.form-floating:not(.form-control:disabled)::before {
  background: transparent;
}
.bookingMask .form-floating > .form-control, .bookingMask .form-floating > label{padding: 1rem 0.15rem;}
.bookingMask .form-floating > label{margin-left: 0;}
.accountingWrapper .ps {
  height: 300px;
}
table.packs {
  width: 100%;
  margin: 0;
  td,th {
    font-size: 12px;
    padding: 4px;
    border-bottom: 3px solid #fff;
    vertical-align: center;
    .btn {
      padding: 0px;
      width: 24px;
      height: 24px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  tr.bg-grey {
    td {
      background-color: #f5f5f5;
    }
  }
  tr.bg-grey2 {
    td {
      color:#333;
      background-color: #a5a5a5;
    }
  }
}
p.hint {
  text-align: left;
  color: #696969 !important;
  font-size: 12px;
}
.btn-mini {
  padding: 0 !important;
  height: 30px;
  border-radius: 0 !important;
  width: 30px;
}

.bgCard .btn {
  margin-bottom: 0;
  margin-right: 6px;
}
p.btnGroup {
  float: right;
  .btn {
    margin: 6px;
  }
}
.secondRow {
  display: none !important;
}

table.accountingKeys, table.accountingDataTable {
  td, th {
    font-size: 12px;
    padding: 5px 12px;
    vertical-align: top;
  }
}
.accountData table.accountingDataTable th{
  color: #333;
}
.accountData hr{
  margin-bottom: 3px;
}
.accountData p{
  margin-bottom: 0;
}
.splits .hint {
  color:#000;
  font-weight: bold;
}
.splits .ps {
  height: 170px;
}
.accountingTab {
  &.card-body {
    position: fixed;
    bottom: 24px;
    z-index: 2;
    width: 83%;
    border-radius: 16px;
    box-shadow: 0 0 10px #ccc;
    background: #f3f3f3;
  }
}
.bookingMask .row > * {
  padding: 0 5px;

  .form-control {
    font-size: 12px !important;
    height: 52px;
    padding-top: 23px;
    padding-bottom: 5px;
  }
}

.align-right {
  text-align: right;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  height: 12px;
  line-height: 12px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.split {
  background: #f3f5f6;
  padding: 6px;
  width: 100%;
  margin-bottom: 2px;
}

.splits {
  margin-bottom: 12px;
}

.split span {
  float: right;
  background: #fb6340;
  width: 20px;
  text-align: center;
  color: #fff;
}
.col-12.firstrow .form-floating {
  width: 5%;
  float: left;
  margin-right: 5px;
  &.form-betrag, &.form-datum {
    width: 6%;
  }
  &.form-btns {
    width: 18%;
  }
  &.form-bu {
    width: 5%;
  }
  &.form-beleg1, &.form-beleg2 {
    width: 10%;
  }

  &.form-sollhaben {
    width: 6%;
  }
  &.form-butext {
    width: 25%;
  }
}
.infoLine table {
  width: 100%;
}
button.btn-small {
  padding: 12px 10px;
  font-size: 13px;
  border-radius: 5px;
  font-weight: normal;
}
.bookingMask .hint {
  color: #333
}
button[disabled]{
  cursor: not-allowed  !important;
}
