.plugin-list {

		.plugin-item{
			&.headline {
				.pluginLineWrap:hover {
					background: transparent;
				}
				.pluginLineWrap {
					border-left-color: $light;
					* {
						color: $grey;
					}
				}
				.lineitem {

					> span + i {
						margin-left: 6px;
						color: $blue;
					}
					span {
						cursor: pointer;
						@include font-size(14);
					}
					span.active {
						color: $blue;
						font-weight: 500;
					}
				}
			}
		}

	.ps {
		clear: both;
	}
	.pluginItem{
		&.activePlugin{
			box-shadow: 0px 3px 6px #00000029;
			border-radius: $br;
			margin-bottom: 24px;
			> .card  {
				border-radius: 0 0 $br $br;
				margin-bottom: 2px;
				> .card-header {
					border-radius: 0;
					h6{
						margin-bottom: 0;
						line-height: 31px;
					}
				}
				> .card-body {
					border-top: 0;
				}
			}
		}
	}
	.plugin-item {
		&.activePlugin {
			.pluginLineWrap {
				border-bottom: 0;
				margin-bottom: 0;
					background: $bgbox;
				border-radius: $br $br 0 0;

				.lineitem {
				}
			}

		}
		.pluginLineWrap {
			margin-bottom: 0;
			transition: .5s all ease 0s;
			cursor: pointer;
			display: flex;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid $border;
			span.pluginInfo {
				@include font-size(11, 1.5);
				display: block;
				font-weight: normal;
				white-space: pre;
				bottom: 0;
				position: absolute;
			}
			&:hover {
				background: $bgbox;
			}
			.lineitem {
				transition: .3s all ease 0s;
				padding-top: 8px;
				padding-bottom: 9px;
				padding-left: 6px;
				padding-right: 6px;
				min-width: 32px;
				&.l-checkbox {
					padding-left: 12px;
					padding-right: 6px;
				}
				&.l-avatar {
					text-align: center;
					margin-left: 32px;
					padding-right: 12px;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-number {
					padding-left: 12px;
					padding-right: 12px;
					width: 105px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					text-align: center;
					display: none;
					@media #{$small}{
						display: block;
					}
				}
				&.l-company {
					width: 220px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					font-weight: 500;
					@include font-size(14, 1.8);
					max-width: 100%;
					padding-right: 12px;
					position: relative;
					@media #{$large}{
						width: 320px;
					}
				}
				&.l-iban{
					display: none;
					@media #{$hd}{
						display: block;
					}
					width: 218px;
					@include font-size(14);
				}
				&.l-title {
					width: 220px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					font-weight: 500;
					@include font-size(14, 1.8);
					max-width: 100%;
					padding-right: 12px;
					position: relative;
					@media #{$large}{
						width: 320px;
					}
				}
				&.l-email {
					width: 200px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(14, 1.8);
					display: none;
					@media #{$large}{
						display: block;
					}
					button {
						white-space: nowrap;
						text-overflow: ellipsis;;
						overflow: hidden;
						max-width: 100%;
					}
				}
				&.l-date {
					width: 200px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(14, 1.8);
					display: none;
					@media #{$large}{
						display: block;
					}

				}
				&.l-prio {
					padding-right: 24px;
					padding-left: 12px;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-akquise {
					text-align: center;
				}
				&.l-state {
				}
				&.l-type {
					text-align: center;
					width: 70px;
					display: none;
					@media #{$medium}{
						display: block;
					}
					.badge {
						width: 100%;
						background: transparent;
						text-align: left;
						border: 0;
						padding-left: 0;
					}
				}
				&.last {
					padding-right: 16px;
				}
			}
		}
	}
	&.card-body > .plugin-item.activePlugin {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		.pluginLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		> .card-body {
			@include clear();
			border-radius: 0 0 $br $br;
			border-top: 0;
			margin-bottom: 12px;
			.card {
				border: 0;
			}
		}
		.pluginContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
}
.inlinePasswordEdit{
	@include clear();
	padding-top: 12px;
	padding-bottom: 12px;
}
.pluginWrap {
	clear: both;
	padding-top: 6px;
	.plugin-item {
		padding-top: 12px;
		transition: .5s all ease 0s;
		padding-bottom: 9px;
		cursor: pointer;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 320px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				&.l-doctitle {
					width: 440px;
				}
				&.titlelong{
					width: 440px;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-type {
				text-align: center;
				width: 70px;
				.badge {
					width: 100%;
				}
			}
			&.l-action{
				align-self: flex-end;
					padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				.fa-x {
					display: inline-block;
				}

			}
			&.last {
				padding-right: 16px;
			}
		}
	}
}

span.roundBadge {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 100%;
	font-weight: bold;
	color: #fff;
}

.addresswrap {
	.entry {
		a, button{
			color: $base;
		}
		@include font-size(14);
		p {
			@include font-size(14);
		}
		padding-bottom: 6px;
		border-bottom: 1px solid $border;
		margin-bottom: 6px;
		@include clear();
		// calc width 100% - 24px
		width: calc(100% - 24px);
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
	.btn-group{
		clear: left;
		float: left;
		min-width: 70%;
	}
}

.infoWrapper {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	max-width: 100%;
	.info {
		background: $bgbox;
		padding: 6px;
		.infoType {
			border-bottom: 1px solid $border;
			font-weight: 500;
		}

	}
}
.preInfoContent {
	white-space: pre;
}
.pageCounter {
	position: absolute;
	right: 12px;
	bottom: 22px;
}

.callNumber{
	color: $link;
	background: transparent;
	border: 0;
	margin-right: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
}
.btn-group{
	.callNumber{
		opacity: .3;
	}
	&:hover{
		.callNumber{
			opacity: 1;
		}
	}

}
.mainContact {
	a, button {
		@include font-size(14, 1.5);
		color: $base;
	}
	> .btn-group, > button.copyClip{
		@include clear();
		width: auto;
		text-align: left;
	}
}

.pluginTabs {
	@include clear();
	@include font-size(14, 1.5);

}
.activityWrap {
	@include clear();
	.row {
		margin-left: 0;
		margin-right: 0;
		border-bottom: 1px solid $border;
		padding-bottom: 12px;
	}
	> .row {
		padding-bottom: 0;
	}
}

.pluginContent {
	h6.card-header {
		display: flex;
		align-items: center;
		i {
			margin-right: 6px;
			margin-top: 2px;
		}
		> button, > .btn, .dropdown > .btn {
			margin-left: auto;
			margin-bottom: 0;
		}
		.btn-group {
			margin-left: auto;
		}
	}
	.card > .card-header{
		border-radius: 0;
	}
}
// DFB Custom STyling

.appView.dfb.plugin-list{
	.pluginWrap .plugin-item {
		.lineitem{
			&.l-number{
				width: 75px;

			}
			&.l-title{
				width: 220px;
				@media #{$xlarge}{
					width: 320px;
				}
			}
		}
	}
	.containerInline{
		td, span, p {
		@include font-size(14);
	}}
}
