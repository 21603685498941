
  th, td {
    border-bottom-color: red;
  }
  .containerInline {
    padding: 24px;
  }
  .inline table.table {
    margin-bottom: 0px;
  }
  .inline table {
    width: 100%;
  }
  .complete table td, .inline table td {
    text-align: right;
    width: 24%;
    &:first-child {
      width: 52%;
      text-align: left;
    }
    &:last-child {
      color: #ccc;
    }
  }
  .profileImage{

    max-height: 200px;
    max-width: 100%;

  }

  table.profileData{
    float: left;
    max-width: 100%;
    width: 400px;
  }
