
.smallerBtn {
  padding: 2px 5px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
}
.l-infos {
  span {
    line-height: 15px;
     @media (min-width: 1600px){
     min-width: 200px;
     }
    &.badge-secondary {
      background: #969696;
      border: 1px solid #969696;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.badge-outline {
      border: 1px solid #969696;
      color: #969696;
      background: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.product-item {
  cursor: pointer;
}

.plugins.dfb .card-body hr {
  margin-top: 8px;
  margin-bottom: 12px;
}
.pluginWrap span.roundBadge{
  background: #969696;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
