

.ck-content {
  min-height: 200px;
}
.product-item {
  cursor: pointer;
}
.boxGrey {
  padding: 24px;
  background-color: #eee;
  min-height: 500px;
}

.fixedButtonDFB .btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 24px 48px;
  /* background: red; */
  /* color: #fff; */
}
