@media #{$small-max} {
	.btn-hide-mobile, .overviewCounter {
		display: none;
	}
	aside.navbar.closed {
		width: 40px;
		min-width: 40px;
	}
	.appContainer{
		padding-left: 12px;
		padding-right: 12px;
	}
	aside.sidenav.closed ~ .main-content {
		margin-left: 40px;
	}
	.customer-list .customer-item .customerLineWrap .lineitem {
		&.l-company {
			@include font-size(12, 1.8);
			width: 162px;
		}
		&.l-akquise, &.actions {
			display: none;
		}
		&.l-checkbox {
			margin-right: 2px;
			padding-left: 2px;
			min-width: 24px;
			padding-bottom: 3px;
		}
	}
	.customer-list .customer-item.headline .customerLineWrap .lineitem.l-checkbox {
		padding-top: 0;
	}
	.customerContent {
		h6.card-header .btn {
			font-size: 0;
			i {
				font-size: 12px;
			}
		}
		.card-body {
			padding: 6px;
		}
	}
	ul.tabNav {
		flex-direction: column;
		li {
			text-align: left;
			i {
				margin-right: 6px;
			}
			br {
				display: none;
			}
			small {
				display: inline;
			}
			&.documents, &.events {
				display: none;
			}
		}
	}
}
